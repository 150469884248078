body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.container {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.text {
  position: absolute;
  top: 50px;
  right: 50px;
  color: white;
  font-size: 24px;
  text-align: right;
  width: 300px;
}

/* Style for the contract address with background */
.contract {
  color: white;
  cursor: pointer;
  font-size: 24px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid white;
  border-radius: 10px;
  margin-top: 20px;
}

.contract:hover {
  text-decoration: underline;
}

/* Styling for the candle */
.candle {
  width: 80px; /* Slightly bigger candle */
  position: absolute;
  bottom: 400px;
  animation: bounce 2s infinite ease-in-out; /* Apply bounce animation */
}

/* Bounce animation for the candle */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Bounces by 20px */
  }
}

/* Styling for the icons with white shadow for visibility */
.icons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.icons img {
  width: 50px;
  margin: 0 15px;
  filter: drop-shadow(0px 0px 5px white); /* Add white shadow for visibility */
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .text {
    width: 250px;
    font-size: 18px;
    top: 40px;
    right: 20px;
  }

  .title {
    font-size: 24px;
    left: 15px;
    top: 15px;
  }

  .contract {
    font-size: 18px;
    padding: 8px 16px;
    margin-top: 15px;
  }

  .icons img {
    width: 40px;
    margin: 0 10px;
  }

  .candle {
    bottom: 300px;
    width: 60px; /* Adjust candle size for mobile */
  }
}
